import React from "react";
import {Navigate} from 'react-router-dom';
export default function UserPassManager(){
    if(localStorage.getItem('loginstatus')!=='true'){
        return(
            <Navigate to={'/logout'}/>
        );
    }else{
        return(
            <>
            <div className="container mt-5">f</div>
            </>
        );
    }
}