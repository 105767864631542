import React from "react";

export default function Technology() {return(
    <>
        <section className="technologies technologies-section dot-background">
            <div className="container" data-aos="fade-up">
                <div className="row content">
                    <div className="col-lg-5  col-12" data-aos="fade-right">
                        <div className="technologies-headng">
                            <div>
                                <h2>Technologies we use</h2>
                                <p style={{textAlign: 'justify'}}>Technology and technicians are getting upated day by day, so we do. We take advantage of various available technologies to provide the best suitable facility to your business. Whether it be a fast responsive website that serves content rapidly fast or a strong back-end infrastructure to maintain heavy database work, we have alredy fastened our belts to handle them all.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12 pt-4 pt-lg-0" data-aos="fade-left">
                        <div className="shadow rounded technologies-group">
                            <ul>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/php.png" className="img-fluid" alt="icon-php"/>
                                    </div>
                                    <span>Core PHP</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/python.png" className="img-fluid" alt="icon-python"/>
                                    </div>
                                    <span>Python</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/node-js.png" className="img-fluid" alt="icon-node-js"/>
                                    </div>
                                    <span>Node Js</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/react-js.png" className="img-fluid" alt="icon-react-js"/>
                                    </div>
                                    <span>React Js</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/mysql.png" className="img-fluid" alt="icon-mysql"/>
                                    </div>
                                    <span>Mysql</span>
                                </li>
                            </ul>
                        </div>
                        <div className="shadow rounded technologies-group">
                            <ul>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/html.png" className="img-fluid" alt="icon-html"/>
                                    </div>
                                    <span>Html</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/css.png" className="img-fluid" alt="icon-css"/>
                                    </div>
                                    <span>Css</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/jquery.png" className="img-fluid" alt="icon-jquery"/>
                                    </div>
                                    <span>Jquery</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/expo.png" className="img-fluid" alt="icon-expo"/>
                                    </div>
                                    <span>Expo</span>
                                </li>
                                <li>
                                    <div className="technologies-icon">
                                        <img src="assets/img/icons/javascript.png" className="img-fluid" alt="icon-javascript"/>
                                    </div>
                                    <span>Javascript</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
);}