import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_PATH, TARGET_TEAM_LEAD, TARGET_TELE_SALES } from "../../../../config";


export default function DashTeamLeadTelesales(){
    
    const[dataToday, setDataToday] = useState([]);
    const[dataGrouped, setDataGrouped] = useState([]);
    const location= useLocation();
    useEffect(()=>{
        axios.post(API_PATH+'CallManagement.php',{
            action : 'fetchCallingProgressTodayForTeam', 
            userid : localStorage.getItem('userid'),
            usertoken : localStorage.getItem('usertoken')
        }).then(r=>{
            if(r.data.status==='success'){
                setDataToday(r.data.retval);
            }
        });
        axios.post(API_PATH+'CallManagement.php',{
            action : 'fetchCallingProgressTodayGroupedByUser', 
            userid : localStorage.getItem('userid'),
            usertoken : localStorage.getItem('usertoken')
        }).then(r=>{
            console.log(r.data)
            if(r.data.status==='success'){
                setDataGrouped(r.data.retval);
            }
        });
    },[location.pathname]);
    return(
        <>
        <div id="dashboard-telesales" className="container mt-5 mb-5">
            <div className="p-2">
                <h4 className="text-center">Hi {localStorage.getItem('username')}</h4>
            </div>
            <div className="shadow rounded p-2 text-center">
                <div className="pb-3">Today's Records</div> 
                <div className="row">
                    <div className="col-sm-3">
                        <div className="shadow rounded p-1 pt-2">
                            <h6>Dialed</h6>
                            <h4>
                                {
                                    dataToday.dialed > 0 ?
                                    dataToday.dialed : 0
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="shadow rounded p-1 pt-2">
                            <h6>Connected</h6>
                            <h4>
                                {
                                    Number(dataToday.follow_up)+Number(dataToday.interested)+Number(dataToday.not_interested)>0 ?
                                    Number(dataToday.follow_up)+Number(dataToday.interested)+Number(dataToday.not_interested) : 0
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="shadow rounded p-1 pt-2">
                            <h6>Prospects</h6>
                            <h4>
                                {
                                    Number(dataToday.interested) > 0 ?
                                    Number(dataToday.interested) : 0
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="shadow rounded p-1 pt-2">
                            <h6>Performance</h6>
                            <h4>
                            {
                                Number(dataToday.follow_up)+Number(dataToday.interested)+Number(dataToday.not_interested)>0 ? 
                                ((Number(dataToday.follow_up)+Number(dataToday.interested)+Number(dataToday.not_interested)*100)/TARGET_TEAM_LEAD)+' %' : 0
                            }
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="mt-5 m-0" style={{overflowX:'scroll'}}>
                    <div className="pb-3">Today's Records</div> 
                    <table className="table table-striped">
                        <thead>
                            <tr key="">
                                <th>View Leads</th>
                                <th>Candidate</th>
                                <th>Dialed</th>
                                <th>Connected</th>
                                <th>Prospect</th>
                                <th>Performance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataGrouped.length>=1 && 
                                dataGrouped.map(dw=>{
                                    return(
                                        <tr key={dw.user_name}>
                                            <td>
                                                <Link to={'/emp-wise-view/'+dw.user_name}>
                                                    <i className="bi bi-eye text-success fs-4"></i>
                                                </Link>
                                            </td>
                                            <td>
                                                {
                                                    dw.user_name
                                                }
                                            </td>
                                            <td>
                                                {
                                                    Number(dw.dialed)>0 ? 
                                                    Number(dw.dialed) : 0
                                                }
                                            </td>
                                            <td>
                                                {
                                                    Number(dw.follow_up)+Number(dw.interested)+Number(dw.not_interested)>0 ? 
                                                    Number(dw.follow_up)+Number(dw.interested)+Number(dw.not_interested) : 0
                                                }
                                            </td>
                                            <td>
                                                {
                                                    Number(dw.interested)>0 ? 
                                                    Number(dw.interested) : 0
                                                }
                                            </td>
                                            <td>
                                                {
                                                    Number(dw.follow_up)+Number(dw.interested)+Number(dw.not_interested)>0 ? 
                                                    ((Number(dw.follow_up)+Number(dw.interested)+Number(dw.not_interested)*100)/TARGET_TELE_SALES)+' %' : 0
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        
                    </table>
                </div>
            </div>
        </div>
        </>
    );
}