import React from "react";
import './home.css';

export default function WhatWeDo() {
    return(
        <>
            <section id="services" className="services section-bg">
                <div className="container" data-aos="fade-up">
                <div className="section-title ">
                    <h2>What we do</h2>
                    <p>We start with a deep discussion over your project and analyse market situations you are going to face in further next years and make a framework that helps you overcome all the obstacles. From designing your ideas in a computer program to Re-Launch your business, we help you build a complete new business profile.</p>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="shadow  icon-box iconbox-blue">
                            <div className="icon">
                            <span><img src="assets/img/icons/idea.png" alt="icon-idea"/></span>
                            </div>
                            <h4><a href="/">Originate Ideas</a></h4>
                            <p>We discuss you original idea and help you make plans, develop your ideas into action towards success.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                    <div className="shadow icon-box iconbox-orange ">
                        <div className="icon">
                        <span><img src="assets/img/icons/deep-analysis.png" alt="icon-analysis"/></span>
                        </div>
                        <h4><a href="/">Deep Analysis</a></h4>
                        <p>We help you with our market expertise on every single important detail to eliminate any chance of error.</p>
                    </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                    <div className="shadow icon-box iconbox-pink">
                    <div className="icon">
                        <span><img src="assets/img/icons/build-profile.png" alt="icon-profile"/></span>
                        </div>
                        <h4><a href="/">Build Profile</a></h4>
                        <p>Compare your business with your competitors and Build profile for your target audience.</p>
                    </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                    <div className="shadow  icon-box iconbox-pink">
                    <div className="icon">
                        <span><img src="assets/img/icons/develop.png" alt="icon-develop"/></span>
                        </div>
                        <h4><a href="/">Development</a></h4>
                        <p>We develop the best suited product to meet your business requirements .</p>
                    </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                    <div className="shadow icon-box iconbox-pink">
                    <div className="icon">
                        <span><img src="assets/img/icons/test.png" alt="icon-test"/></span>
                        </div>
                        <h4><a href="/">Testing</a></h4>
                        <p>We test the product to be fullproof before the launch, so we can eleminate all the error.</p>
                    </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                    <div className="shadow icon-box iconbox-yellow">
                    <div className="icon">
                        <span><img src="assets/img/icons/project-launch.png" alt="icon-launch"/></span>
                        </div>
                        <h4><a href="/">We Re-Launch you</a></h4>
                        <p>Alt last, we re-launch you and make sure to keep your business running smooth, steady and profitable.</p>
                    </div>
                    </div>
                </div>

                </div>
            </section>
            <section className="product-engineering">
                <div className="container" data-aos="fade-up">
                    <div className="section-title ">
                        <h2>How it Goes</h2>
                        <p>We have a very simple 4 stage development procedure. It starts with your project idea and goes on and on.</p>
                    </div>
                </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="product-engineering-box">
                                    <span className="count">
                                        1
                                    </span>
                                    <div className="icons">
                                        <img src="assets/img/icons/ideation.png" width="20%" alt=""/>
                                    </div>
                                    <div className="title">
                                        <p>Ideation</p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product-engineering-box">
                                    <span className="count">
                                        2
                                    </span>
                                    <div className="icons">
                                        <img src="assets/img/icons/architecture.png" width="20%" alt=""/>
                                    </div>
                                    <div className="title">
                                        <p>Framework</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product-engineering-box">
                                    <span className="count">
                                        3
                                    </span>
                                    <div className="icons">
                                        <img src="assets/img/icons/design.png" width="20%" alt=""/>
                                    </div>
                                    <div className="title">
                                        <p>Designing</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="product-engineering-box">
                                    <span className="count">
                                        4
                                    </span>
                                    <div className="icons">
                                        <img src="assets/img/icons/development.png" width="20%" alt=""/>
                                    </div>
                                    <div className="title">
                                        <p>Development</p>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* <div className="col-md-3">
                                <div className="product-engineering-box">
                                    <span className="count">
                                        5
                                    </span>
                                    <div className="icons">
                                        <img src="assets/img/icons/testing.png" width="20%" alt=""/>
                                    </div>
                                    <div className="title">
                                        <p>Testing</p>
                                    </div>
                                    
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
        </>
    );
}