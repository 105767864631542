import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router, 
  Routes, 
  Route 
} from "react-router-dom"
import Header         from './layout/common/Header';
import Footer         from './layout/common/Footer';
import AboutTeam      from './layout/about-us/AboutTeam';
import AboutServices  from './layout/about-us/AboutServices';
import AboutInnovilla from './layout/about-us/AboutInnovilla';
import AboutContact   from './layout/about-us/AboutContact';
import AboutCareer    from './layout/about-us/AboutCareer';
import AboutUs        from "./layout/home/AboutUs";
import Technology     from "./layout/home/Technology";
import WhatWeDo       from "./layout/home/WhatWeDo";
import Login          from './layout/common/Login';
import Iv404          from './layout/common/Iv404';
import Logout from './layout/common/Logout';
import UserPassManager from './layout/protected/user-management/UserPassManager';
import DashboardSwitch from './layout/protected/user-management/DashboardSwitch';
import Dialer from './layout/protected/call-management/Dialer';
import FollowUps from './layout/protected/call-management/FollowUps';
import Prospects from './layout/protected/call-management/Prospects';
import ClearCache from './layout/common/ClearCache';
import AboutReviews from './layout/about-us/AboutReviews';
import Certverify from './layout/common/Certverify';
import DailyNumberedView from './layout/protected/call-management/DailyNumberedView';
import PolicyCookie from './layout/common/PolicyCookie'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router basename="/">
        <Header/>
        <Routes>
          <Route exact path="/" element={
            <>
            <ClearCache/>
            <AboutUs/>
            <WhatWeDo/>
            <Technology/>
            </>
          }/>
          <Route path='/about' element={<AboutInnovilla/>}/>
          <Route path='/team' element={<AboutTeam/>}/>
          <Route path='/services' element={<AboutServices/>}/>
          <Route path='/career' element={<AboutCareer/>}/>
          <Route path='/contact' element={<AboutContact/>}/>
          <Route path='/customer-reviews' element={<AboutReviews/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/logout' element={<Logout/>}/>
          <Route path='/certverify' element={<Certverify/>}>
            <Route path='/certverify/:id' element={<Certverify/>}/>
          </Route>
          <Route path='*' element={<Iv404/>}/>
          <Route path='cookie-policy' element={<PolicyCookie/>}/>

          {/* protected Routes */}
          <Route path='/password' element={<UserPassManager/>}/>
          <Route path='/dashboard' element={<DashboardSwitch/>}/>
          <Route path='/dialer' element={<Dialer/>}/>
          <Route path='/follow-ups' element={<FollowUps/>}/>
          <Route path='/prospects' element={<Prospects/>}/>
          <Route path='/emp-wise-view' element={<DailyNumberedView/>}>
            <Route path='/emp-wise-view/:empname' element={<DailyNumberedView/>}/>
          </Route>
          {/* protected Routes */}
        </Routes>
        <Footer/>
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
