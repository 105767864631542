import axios from "axios";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from '../../config';


export default function Login(){
    const goto = useNavigate();
    const[userId, setUserId] = useState('');
    const[userPassword, setUserPassword] = useState('');
    const[statusMsg, setStatusMsg] = useState('');

    const login = ()=>{
        axios.post(API_PATH+'Auth.php',{
            action :'userLogin',
            loginId : userId,
            password : userPassword
        }).then(res=>{
            console.log(res.data);
            if(res.data.status==='success'){
                localStorage.setItem('loginstatus','true');
                localStorage.setItem('usertoken',res.data.usertoken);
                localStorage.setItem('userid',res.data.userid);
                localStorage.setItem('userrole',res.data.userrole);
                localStorage.setItem('username',res.data.username);
                setStatusMsg('success');
                goto('/dashboard');
            }else{
                setStatusMsg('failed');
            }
        }).catch(err=>{
            console.log(err);
        });
    }
    
    if(localStorage.getItem('loginstatus')==='true'){
        return(
            <Navigate to={'/dashboard'}/>
        );
    }else{
        return(
            <>
            <div>
                <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="text-center mb-2">
                                <h4 className="text-center text-secondary">Login</h4>
                                <small >For company professionals only</small>
                            </div>
                            {
                                statusMsg==='failed'
                                &&
                                <div className="bg-danger p-1 rounded shadow mb-1 text-white text-center">Something went wrong, please try again.</div>
                            }
                            <div className="shadow rounded p-2">
                                <div className="p-1">
                                    <label htmlFor="UserId">User Id</label>
                                    <input type="text" className="form-control" id="UserId" onChange={(e)=>{setUserId(e.target.value)}}/>
                                </div>
                                <div className="p-1">
                                    <label htmlFor="UserPassword">User Password</label>
                                    <input type="password" className="form-control" id="UserPassword" onChange={(e)=>{setUserPassword(e.target.value)}}/>
                                </div>
                                <div className="p-1">
                                    <button className="btn w-100 btn-success" onClick={login}>Login</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}