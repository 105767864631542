import React from "react";
export default function AboutCareer(){
    return(
        <>
        <section className="career-page">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-7 pt-5">
                        <div className="section-title">
                            <h3>Company with remote first employment approach</h3>
                        </div>	
                        <div>
                            <ul className="intern-list">
                                <li>Better balance between work and personal life space. We are providing both freedom and flexibility.</li>
                                <li>Save your time, Enhance your skills and productivity with your savings. </li>
                                <li>Because, bean bags are more comfortable and you can setup your own office at home. </li>
                                <li>And your get an opportunity to work with best team selected from across the nation and even from beyond borders.</li>
                                <li>Healthy and happy life.</li>
                            </ul>
                        </div>	
                    </div>
                    <div className="col-md-5">
                        <img src="assets/img/career/remote-job.png" className="w-100" alt=""/>
                    </div>
                </div>
            </div>
            <div className="container mt-5" data-aos="fade-up">
                <div className="section-title">
                    <h2>Current Openings at Innovilla</h2>
                </div>
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="accordion accordian-customize career-accordian" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        1. Human Resource Intern
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body"> 
                                        <p>The candidate will join the company as “Intern – Human Resource” which involves it in following responsibilities –</p>
                                        <ul className="intern-list">
                                            <li>Gather contact information of prospective candidates and prepare a datasheet containing all the related information.</li>
                                            <li>Contacting prospective candidates by calling and generating appointments to discuss the Employment details .</li>
                                            <li>Following up with data and preparing a datasheet for responses received from prospective candidates.</li>
                                            <li>Conducting interviews and shortlisting candidates.</li>
                                            <li>Preparing and submitting documents for finalized candidates.</li>
                                            <li>Monitoring Attendance and preparing datasheets.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    2. Sales & Marketing
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body"> 
                                        <p>The candidate will join the company as “Sales Specialist” which involves it in following responsibilities –</p>
                                        <ul className="intern-list">
                                            <li>Conduct market research to identify selling possibilities and evaluate customer needs</li>
                                            <li>Actively seek out new sales opportunities through cold calling, networking and social media</li>
                                            <li>Set up meetings with potential clients and listen to their wishes and concerns</li>
                                            <li>Prepare and deliver appropriate presentations on products and services</li>
                                            <li>Create frequent reviews and reports with sales and financial data</li>
                                            <li>Negotiate/close deals and handle complaints or objections</li>
                                            <li>Collaborate with team members to achieve better results</li>
                                            <li>Gather feedback from customers or prospects and share with internal teams</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    3. Web Development Intern
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body"> 
                                        <p>The candidate will join the company as “Intern – Web Developer” which involves it in following responsibilities –</p>
                                        <ul className="intern-list">
                                            <li>Writing well designed, testable, efficient code by using good software development practices.</li>
                                            <li>Creating website layout/user interface by using standard HTML/CSS practices.</li>
                                            <li>Integrating data from various back-end services and databases.</li>
                                            <li>Gathering and refining specifications and requirements based on technical needs.</li>
                                            <li>Creating and maintaining software documentation.</li>
                                            <li>Maintaining, expanding, and scaling sites.</li>
                                            <li>Keep up to date into emerging technologies/industry trends and apply them into operations and activities.</li>
                                            <li>Collaborates with web designers to match the intent of visual design.</li>
                                        </ul>
                                        
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>	
        </>
    );
}