import React, { useEffect, useState } from "react";
import Nav from './Nav';
import {Link} from 'react-router-dom';
import './style.css';
import {useLocation} from 'react-router-dom';
import { BASE_PATH, BASE_URL } from "../../config";



export default function Header() {
    const [navToggleVal, setNavToggleVal] = useState(-150);
    const location = useLocation();
    console.log(BASE_PATH+'assets/img/header-logo.png');
    const navToggle = ()=>{
        if(navToggleVal===(-150)){
            setNavToggleVal(0);
        }else{
            setNavToggleVal(-150);
        }
        return;
    }
    useEffect(()=>{
        if(navToggleVal===0){
            setNavToggleVal(-150);
        }
    },[location.pathname]);

    return(
        <>
            <header id="header" className="fixed-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="main-logo">
                                <Link to={'/'}>
                                    <img src={BASE_URL+'/assets/img/header-logo.png'} className="w-100" alt="Innovilla Private Limited Logo"/>
                                </Link>
                                <div className="menu-toggle-icon">
                                    <i className="bi bi-x-diamond-fill" style={{fontSize:"20px", color:'black'}} onClick={navToggle}/>
                                    <div className="mobile-menu" style={{transform: `translateX(${navToggleVal}%)`}} id="navbar">
                                        <div className="mobile-logo">
                                            <Link to={'/'}>
                                                <img src={BASE_URL+'/assets/img/header-logo.png'} className="w-100" alt="Innovilla Pvt.Ltd Logo"/>
                                            </Link>
                                            <i className="bi bi-x-diamond-fill" style={{fontSize:"20px", color:'black'}}  onClick={navToggle}/>
                                        </div>
                                        <Nav/>
                                        <div className="mobile-social-link">
                                            <a href="https://twitter.com/Innovillaoffice" className="twitter"><i className="bu bi-twitter"></i></a>
                                            <a href="https://www.facebook.com/innovilla.in" className="facebook"><i className="bu bi-facebook"></i></a>
                                            <a href="https://www.instagram.com/innovillaoffice" className="instagram"><i className="bu bi-instagram"></i></a>
                                            <a href="https://www.linkedin.com/company/innovilla-private-limited" className="linkedin"><i className="bu bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <div className="col-lg-8">
                            <nav className="main-nav">
                            <Nav/>
                            </nav>
                        </div>
                        <div className="col-lg-2">
                            <div className="header-social-links d-flex align-items-center">
                                <a href="https://twitter.com/Innovillaoffice" className="twitter"><i className="bu bi-twitter"></i></a>
                                <a href="https://www.facebook.com/innovilla.in" className="facebook"><i className="bu bi-facebook"></i></a>
                                <a href="https://www.instagram.com/innovillaoffice" className="instagram"><i className="bu bi-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/innovilla-private-limited" className="linkedin"><i className="bu bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}