import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import DashboardTelesales from "./dashboard/DashboardTelesales";
import DashTeamLeadTelesales from "./dashboard/DashTeamLeadTelesales";

export default function DashboardSwitch(){
    const [UserRole, SetUserRole] = useState('public');
    const location = useLocation();
    
    useEffect(()=>{
        SetUserRole(localStorage.getItem('userrole'));
    },[location.pathname]);
    if(localStorage.getItem('loginstatus')!=='true'){
        return(
            <Navigate to={'/logout'}/>
        );
    }else{
        switch (UserRole) {
            case 'admin':
                return(
                    <DashTeamLeadTelesales/>
                );
                // eslint-disable-next-line
                break;
        
            case 'telesales':
                return(
                    <DashboardTelesales/>
                );
                // eslint-disable-next-line
                break;
                
            case 'team-lead-telesales':
                return(
                    <DashTeamLeadTelesales/>
                );
                // eslint-disable-next-line
                break;
                
            default:
                return(
                    <></>
                );
                // eslint-disable-next-line
                break;
        }
    }
}