import React, { useEffect, useState } from "react";
import {Link, useLocation} from 'react-router-dom';
import './style.css';

export default function Nav() {
    
    const location = useLocation();
    const [UserRole, SetUserRole] = useState('');
    useEffect(()=>{
        SetUserRole(localStorage.getItem('userrole'));
    },[location.pathname]);

    if(UserRole==='admin') {
        return(
            <>
                <ul>
                    <li><Link to={"/dashboard"}>Dashboard</Link></li>
                    <li>
                        <Link to={'#'}>Call Manager</Link>
                        <ul>
                            <li><Link to={'/dialer'}>Dial Number</Link></li>
                            <li><Link to={"/follow-ups"}>Follow Ups</Link></li>
                            <li><Link to={"/prospects"}>Prospects</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to={'#'}>Manage</Link>
                        <ul>
                            <li><Link to={'/profile'}>Profile</Link></li>
                            <li><Link to={"/password"}>Password</Link></li>
                            <li><Link to={"/logout"}>Logout</Link></li>
                        </ul>
                    </li>
                </ul>
            </>
        );
    }else if(UserRole==='telesales'){
        return(
            <>
                <ul>
                    <li><Link to={"/dashboard"}>Dashboard</Link></li>
                    <li>
                        <Link to={'#'}>Call Manager</Link>
                        <ul>
                            <li><Link to={'/dialer'}>Dial Number</Link></li>
                            <li><Link to={"/follow-ups"}>Follow Ups</Link></li>
                            <li><Link to={"/prospects"}>Prospects</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to={'#'}>Manage</Link>
                        <ul>
                            <li><Link to={'/profile'}>Profile</Link></li>
                            <li><Link to={"/password"}>Password</Link></li>
                            <li><Link to={"/logout"}>Logout</Link></li>
                        </ul>
                    </li>
                </ul>
            </>
        );
    }else if(UserRole==='team-lead-telesales'){
        return(
            <>
                <ul>
                    <li><Link to={"/dashboard"}>Dashboard</Link></li>
                    <li>
                        <Link to={'#'}>Call Manager</Link>
                        <ul>
                            <li><Link to={'/dialer'}>Dial Number</Link></li>
                            <li><Link to={"/follow-ups"}>Follow Ups</Link></li>
                            <li><Link to={"/prospects"}>Prospects</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to={'#'}>Manage</Link>
                        <ul>
                            <li><Link to={'/profile'}>Profile</Link></li>
                            <li><Link to={"/password"}>Password</Link></li>
                            <li><Link to={"/logout"}>Logout</Link></li>
                        </ul>
                    </li>
                </ul>
            </>
        );
    }else{
        return(
            <>
                <ul>
                    <li><Link to={"/"}>Home</Link></li>
                    <li>
                        <Link to={'#'}>Info</Link>
                        <ul>
                            <li><Link to={'/about'}>About</Link></li>
                            <li><Link to={"/team"}>Team</Link></li>
                            <li><Link to={"/services"}>Services</Link></li>
                            <li><Link to={"/customer-reviews"}>Reviews</Link></li>
                            <li><Link to={"/career"}>Career</Link></li>
                        </ul>
                    </li>
                    {/* <li>
                        <Link to={'#'}>Manage</Link>
                        <ul>
                            <li><Link to={'/login'}>Login</Link></li>
                            <li><Link to={"/team"}>Team</Link></li>
                            <li><Link to={"/services"}>Services</Link></li>
                            <li><Link to={"/career"}>Career</Link></li>
                        </ul>
                    </li> */}
                    <li><a href="https://innovilla.setmore.com/" target="_blank" rel="noopener noreferrer">Appointment</a></li>
                    <li><a href="https://pages.razorpay.com/innovilla" rel="noopener noreferrer">Pay</a></li>
                    <li><Link to={"/contact"}>Contact</Link></li>
                    <li><Link to={"/login"}>Login</Link></li>
                </ul>
            </>
        );
    }
}