import React from "react";
export default function AboutInnovilla(){
    return(
        <>
        <section id="about-us" className="about-us">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Innovilla Private Limited : An introduction</h2>
                    <p>
                        Innovilla is a 'Startup India' recognized Startup working to 
                        build an environment for small scale business to scale in terms 
                        of profitability and extracting the most of available technical 
                        resources. Our team encourages founders to involve technology and 
                        its belongings in their business environment to reduce cost of 
                        operational management, product development, manpower management, 
                        distribution and supply, branding, promotion and so on. 
                    </p>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="accordion accordian-customize" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Why Us
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body"> 
                                    <strong>We are Distinct. </strong> 
                                    Its really very easy to pronounce than performing that one is distinct than other. 
                                    But at Innovilla our first target is to provide you the best quality services at 
                                    best pricing and we know it makes you satisfied. Someone said that happy clients 
                                    bring you fortune and we learnt it. 
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Our Experties
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body"> <strong>Help you do it.</strong> 
                                    We are developers and we help you create your imagination into reality. 
                                    We Assist you setting up technology for your business from root to heights 
                                    and measure your steps towards success. 
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Experience
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body"> 
                                        <strong>From the roots.</strong> 
                                        Our journey starts from the scratch. We are strugglers, hustlers, 
                                        loosers, acheivers and everything that a perfect start up should have. 
                                        So we can easily help others from our journey of learning. The founder 
                                        has arund 12 year's market experience dealing over various profiles and 
                                        processes. 
                                        </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Origin
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body"> 
                                        <strong>Mahadev Ki Nagri.</strong> 
                                        Innovilla has registered address in worlds most ancient and auspecious city Varanasi, Uttar Pradesh, Bhaarat.
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="about-image text-center">
                        <img src="assets/img/about/about.png" className="img-fluid" alt="" width="80%"/>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="skills" className="skills">
            <div className="container">
                <div className="section-title" data-aos="fade-up">
                    <h2>The <strong>Next Big</strong> : Grabju.com</h2>
                    <p>
                        Our Brand new online store with variety of products is on the way to launch. 
                        From cash backs to referal earning, we shall have all the fun ready for our 
                        awesome customers. Our team is working on it and we getting things ready to 
                        stun the world.  
                    </p>
                </div>
                <div className="row skills-content">
                    <div className="col-lg-4 text-center" data-aos="fade-up">
                        <img src="assets/img/about/grabju.png" alt="Grabju.com" width="70%"/>
                    </div>
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <div className="progress"> <span className="skill">Shopping <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="progress"> <span className="skill">Cashback <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="progress"> <span className="skill">Earning <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="progress"> <span className="skill">Garbage <i className="val">0%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
