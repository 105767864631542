import React from "react";
import { BASE_URL } from "../../config";

export default function Footer() {
    return(
        <>
        
        
                <footer id="footer">
                    <div style={{padding:'1px', backgroundColor: '#1bbd36'}}></div>

                    <div className="footer-top">
                        <div className="container">
                            <div className="row">

                            <div className="col-lg-3 col-md-6 col-6 footer-contact">
                                <div className="footer-logo">
                                    <a href="/home">
                                        <img src={BASE_URL+'/assets/img/header-logo.png'} className="img-fluid" alt="Innovilla Pvt. Ltd Footer Logo"/>
                                    </a>
                                </div>
                                <p> <br/>
                                116, Mahamanapuri, Varanasi, <br/>
                                Uttar Pradesh 221005<br/>
                                <strong>Phone:</strong> +91 6393 409431<br/>
                                <strong>Email:</strong> office@innovilla.in<br/>
                                </p>
                            </div>

                            <div className="col-lg-3 col-md-6 col-6 footer-links">
                                <h4>Services</h4>
                                <ul>
                                    <li><a href="/services">Web Development</a></li>
                                    <li><a href="/services">Promotion</a></li>
                                    <li><a href="/services">Sales</a></li>
                                    <li><a href="/services">Outsourcing</a></li>
                                    <li><a href="/services">Ecommerce</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-md-6 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><a href="/about">About us</a></li>
                                    <li><a href="/team">Team</a></li>
                                    <li><a href="/career">Career</a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                    <li><a href="/https://pages.razorpay.com/innovilla">Pay</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 col-6 footer-newsletter">
                                <h4>Join Our Newsletter</h4>
                                <p>Joining this newletter will keep you updated about our ideas and innovations.</p>
                                <form action="#" method="post">
                                <div className="input-group">
                                    <div className="form-outline  shadow rounded">
                                        <input type="search" id="form1" className="form-control" />
                                    </div>
                                    <button type="button" className="btn text-white shadow rounded" style={{backgroundColor: '#1bbd36'}}>
                                    <i className="bi bi-search"/>
                                    </button>
                                </div>
                                </form>
                            </div>

                            </div>
                        </div>
                    </div>
                    <div className="pb-5"></div>
                    <div className="pb-2"></div>

                </footer>

                <a href="/#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </>);

}