
import  axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useParams,} from "react-router-dom";
import { API_PATH } from "../../../config";

export default function DailyNumberedView(){
    const[empWiseCallingData, setEmpWiseCallingData] = useState([]);
    const location= useLocation();
    const param = useParams();
    
    useEffect(()=>{
        axios.post(API_PATH+'CallManagement.php',{
            action : 'fetchCallingDataForTodayEmpWise', 
            userid : localStorage.getItem('userid'),
            usertoken : localStorage.getItem('usertoken'),
            empname : param.empname
        }).then(r=>{
            console.log(r.data)
            if(r.data.status==='success'){
                setEmpWiseCallingData(r.data.retval);
            }
        });
    },[location.pathname]);
    
    if(localStorage.getItem('loginstatus')!=='true'){
        return(
            <Navigate to={'/logout'}/>
        );
    }else{
        return(
            <>
                <div className="container">
                    <div className="mt-5 m-0" style={{overflowX:'scroll'}}>
                        <div className="pb-3">Today's Records</div> 
                        <table className="table table-striped">
                            <thead>
                                <tr key="">
                                    <th>Call</th>
                                    <th>Candidate</th>
                                    <th>Dialed Number</th>
                                    <th>Remark Category</th>
                                    <th>Follow Up Date</th>
                                    <th>Follow Up Time</th>
                                    <th>Detailed Remark</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    empWiseCallingData.length>=1 && 
                                    empWiseCallingData.map(dw=>{
                                        return(
                                            <tr key={dw.id}>
                                                <td>
                                                    <a href={`tel:${dw.contact_number}`}>
                                                        <button 
                                                            className="btn " 
                                                            id={`btn_${dw.id}`} 
                                                            style={{
                                                                backgroundColor:'#03a84e', 
                                                                color:'white'}} 
                                                            onClick={(e)=>{e.target.style.backgroundColor='#cc0000'}}
                                                        >
                                                            <i className="bi bi-telephone-plus-fill"></i> {dw.contact_number}
                                                        </button>
                                                    </a>
                                                </td>
                                                <td>
                                                    {
                                                        dw.user_name
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        Number(dw.called_on)
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dw.remark_category
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dw.follow_up_date
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dw.follow_up_time
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dw.remark_detailed
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dw.date
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dw.time
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                
                                

                            </tbody>
                            
                        </table>
                    </div>
                </div>
            </>
        );
    }
}