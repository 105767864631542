import React from "react";
export default function Iv404(){
    return(
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-5">
                        <img src="./assets/img/lab-pup.jpg" alt="404" className="w-100"/>
                    </div>
                    <div className="col-7">
                        <h1 style={{marginTop:'25%'}}>Sorry ! Page not found.</h1>
                    </div>
                </div>
            </div>
        </>
    );
}