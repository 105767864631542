import React from "react";
import { BASE_URL } from "../../config";
import {
    useParams
  } from "react-router-dom";


  export default function Certverify(){
    let param = useParams();
    let certid = param.id;
    return(
        <>
        <div className="container mt-4 mb-4 text-center">
            <img src={BASE_URL+`/assets/img/certificates/`+certid+`.png`} alt={certid} width="80%"/>
        </div>
        </>
    );
}