import React from "react";
export default function AboutServices(){
    return(
        <>
        <section className="web-design">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="mt-150">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="service-type">
                                        <div className="service-type-icon">
                                            <img src="assets/img/services/promotions.png" alt=""/>
                                        </div>
                                        <h3>Promotions</h3>
                                        <p>As the leading digital marketing company, we have developed a team of digital marketing specialists that have the necessary knowledge to assure the quality of services you offer. We are a leading digital marketing company in India, dedicated to establishing long-term client relationships.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="service-type">
                                        <div className="service-type-icon">
                                            <img src="assets/img/services/sales.png" className="img-fluid" alt=""/>
                                        </div>
                                        <h3>Sales</h3>
                                        <p>We help you increase your market reach by utilizing our team of experienced sales specialists. Our process involves introducing your product or service to a new domestic and international markets, Enhancing your sales channels, aggressive marketing activities, branding support, competition analysis and customer support. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="service-type">
                                    <div className="service-type-icon">
                                        <img src="assets/img/services/web-development.png" alt=""/>
                                    </div>
                                    <h3>Web Development</h3>
                                    <p>Innovilla Private Limited is dedicated to creating the best digital solutions to fit your unique requirements. To achieve optimum client happiness, we have unique instruments and a creative mentality.</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="service-type">
                                    <div className="service-type-icon">
                                        <img src="assets/img/services/integration-service.png" className="img-fluid" alt=""/>
                                    </div>
                                    <h3>Integration Services</h3>
                                    <p>Collecting online payments, sending mails, bulk sms, advertisements or otps we assist in all possible ways to keep your process running smooth. Our integration services are fast and secure and we keep your privacy on top.</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="service-type">
                                    <div className="service-type-icon">
                                        <img src="assets/img/services/ecommerce.png" className="img-fluid" alt=""/>
                                    </div>
                                    <h3>Ecommerce</h3>
                                    <p>We specialize in designing professional, progressive online and e-commerce websites. We ensure that your website is developed with a good business plan and cutting-edge development technology to make the process of having your e-commerce website up and running go as smoothly as possible.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-150">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="service-type">
                                        <div className="service-type-icon">
                                            <img src="assets/img/services/outsourcing.png" alt=""/>
                                        </div>
                                        <h3>Outsourcing</h3>
                                        <p>We handle various processes on behalf of you and on affordable pricing. Our aim is to join hands and find better opportunities together. So all you have to do is just to hand over your work to us and relax.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="service-type">
                                        <div className="service-type-icon">
                                            <img src="assets/img/services/it-services.png" className="img-fluid" alt=""/>
                                        </div>
                                        <h3>Other IT Services</h3>
                                        <p>We provide technical assistance to your business , so can focus playing the core role only. All your day to day technical need can be fulfilled by us with any harassment or wastage of time over establishing and managing thing.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}