
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PATH } from "../../../config";
import { Navigate } from "react-router-dom";

export default function Dialer(){
    const[callingData, setCallingData]=useState([]);
    const [counter, setCounter]=useState(0);
    const [remarkDate, setRemarkDate]=useState(0);
    const [remarkTime, setRemarkTime]=useState(0);
    
    // url encode message to send on whatsapp
    const encodedMsgStr = `Hello Sir/Ma'am, 
        Im `+localStorage.getItem('username')+` from Innovilla Private Limited, we help you setting up your business online. 
        We build websites and web based apps and provide integration services. 
        Please let us know if you have any such requrements. 
        You can visit our website at https://innovilla.in`;
    const encodedMsg = encodeURI(encodedMsgStr);
    
    const updateStatus = (e)=>{
        
        
        var callid      = e.target.id;
        var callRespose = e.target.value;
        var userId      = localStorage.getItem('userid');
        var toHide      = 'parent_container_'+callid
        var remark      = document.getElementById('remark_'+callid).value;
        var dialedNumber = document.getElementById('dialed_number_'+callid).value;
        var followUpDate = remarkDate;
        var followUpTime = remarkTime;
        
        document.getElementById(toHide).classList.add('d-none');
        axios.post(
            API_PATH+'CallManagement.php',{
            action : 'createCallReport',
            callid : callid,
            dialedNumber : dialedNumber,
            callResponse : callRespose,
            userId : userId,
            followUpDate : followUpDate,
            followUpTime : followUpTime,
            usertoken : localStorage.getItem('usertoken'),
            remark : remark

        }).then(r=>{
            console.log(r.data)
            if(r.data.status==='success'){
                setCounter(Number(counter)+1);
            }else{
                document.getElementById(toHide).classList.remove('d-none');
            }
        });
        
    }
    useEffect(()=>{
        axios.post(API_PATH+'CallManagement.php',{
            action : 'fetchCallingData', 
            userid : localStorage.getItem('userid'),
            usertoken : localStorage.getItem('usertoken')
        }).then(r=>{
            if(r.data.status==='success'){
                setCallingData(r.data.retval);
            }
        });
    },[counter]);
    if(localStorage.getItem('loginstatus')!=='true'){
        return(
            <Navigate to={'/logout'}/>
        );
    }else{
        return(
            <>
                <div className="container mt-5 mb-5">
                        <div className="row m-0">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                {callingData.map(pd=>{
                                    return(
                                        <div className="border shadow rounded p-2 mt-2" key={pd.id} id={`parent_container_${pd.id}`}>
                                            <div className="col-12 mt-2">
                                                <div><span className="rounded bg-warning pl-1 pr-1">Business Name :</span> {pd.business_name}</div>
                                                <div className="mt-1"><span className="rounded bg-warning  pl-1 pr-1">Contact Person :</span> {pd.name}</div>
                                                <div className="mt-1"><span className="rounded bg-warning  pl-1 pr-1">City :</span> {pd.city}</div>
                                            </div>
                                            <div className="row m-0 mt-2">
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor={`dialed_number_${pd.id}`}>Click to Call</label>
                                                    <input type="text" id={`dialed_number_${pd.id}`} value={pd.contact_number} readOnly hidden/>
                                                    <a href={`tel:${pd.contact_number}`}>
                                                        <button 
                                                            className="btn form-control" 
                                                            id={`btn_${pd.id}`} 
                                                            style={{
                                                                backgroundColor:'#03a84e', 
                                                                color:'white'}} 
                                                            onClick={(e)=>{e.target.style.backgroundColor='#cc0000'}}
                                                        >
                                                            <i className="bi bi-telephone-plus-fill"></i> {pd.contact_number}
                                                        </button>
                                                    </a>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor={`follow_up_date_${pd.id}`}>Follow Up Date</label>
                                                    <input type="date" className="form-control" id={`follow_up_date${pd.id}`} name={`follow_up_date_${pd.id}`} onChange={(e)=>{setRemarkDate(e.target.value)}}/>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor={`follow_up_time_${pd.id}`}>Follow Up Time</label>
                                                    <input type="time" className="form-control" id={`follow_up_time_${pd.id}`} name={`follow_up_time_${pd.id}`} onChange={(e)=>{setRemarkTime(e.target.value)}}/>
                                                </div>
                                                
                                                <div className="col-12 mt-2">
                                                    <textarea className="form-control" id={`remark_${pd.id}`} rows="5" placeholder="Enter Remark Here"></textarea>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor={`wa_connect_${pd.id}`}>Whatsapp Connect</label>
                                                    <a href={`https://wa.me/91`+pd.contact_number+`/?text=`+encodedMsg} target="_blank" rel="noopener noreferrer">
                                                        <button className="btn form-control" style={{backgroundColor:'#03a84e'}}>
                                                            <i className="bi bi-whatsapp text-white"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                                <div className="col-sm-6 mt-2">
                                                    <label htmlFor={pd.id}>Add a Status (Select after remark)</label>
                                                    <select className="form-control" id={pd.id} onChange={updateStatus}>
                                                        <option value="">Select Status</option>
                                                        <option value="follow_up">Follow Up</option>
                                                        <option value="not_reachable">Not Reachable</option>
                                                        <option value="not_answering">Not Answering</option>
                                                        <option value="not_interested">Not Interested</option>
                                                        <option value="interested">Lead/Interested</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
            </>
        );
    }
}