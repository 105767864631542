import React from "react";
export default function AboutContact(){
    return(
        <>
        <div className="map-section">
            <iframe 
                title="map-location"
                style={{border:'0', width: '100%', height: '350px'}} 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.167779330838!2d82.97732331441908!3d25.264940835150046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e338862122de9%3A0x310850cc7b6e6b7!2sInnovilla%20Private%20Limited!5e0!3m2!1sen!2sin!4v1648296014880!5m2!1sen!2sin" 
                frameborder="0" 
                allowfullscreen>
            </iframe>
        </div>
        <section id="contact" className="contact">
            <div className="container">

                <div className="row justify-content-center" data-aos="fade-up">

                <div className="col-lg-10">

                    <div className="info-wrap">
                    <div className="row">
                        <div className="col-lg-4 info">
                        <i className="bi bi-geo-alt"></i>
                        <h4>Location:</h4>
                        <p>Plot No 116<br/>Mahamanapuri Colony, Varanasi</p>
                        </div>

                        <div className="col-lg-4 info mt-4 mt-lg-0">
                        <i className="bi bi-envelope"></i>
                        <h4>Email:</h4>
                        <p>office@innovilla.in</p>
                        </div>

                        <div className="col-lg-4 info mt-4 mt-lg-0">
                        <i className="bi bi-phone"></i>
                        <h4>Call:</h4>
                        <p>+91 6393 409431</p>
                        </div>
                    </div>
                    </div>

                </div>

                </div>

                <div className="row mt-5 justify-content-center" data-aos="fade-up">
                    {/* <div className="col-lg-10">
                        <div className="row">
                            <div className="col-md-6 form-group">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                            </div>
                            <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
                        </div>
                        <div className="form-group mt-3">
                            <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                        </div>
                        <div className="my-3">
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div className="text-center"><button type="submit">Send Message</button></div>
                        
                    </div> */}

                </div>

            </div>
        </section>
        </>
    );
}
