import React from "react";
import './about.css';
export default function AboutReviews(){
    return(
        <>
            <section className="customer-review">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="customer-review-box">
                                <div className="customer-info">
                                    <div className="cutomer-name">
                                        <h6>Customer Name</h6>
                                        <span>Role</span>
                                        <div className="review-rating">
                                            <span><i className="bi bi-star-fill text-warning"></i></span>
                                            <span><i className="bi bi-star-fill text-warning"></i></span>
                                            <span><i className="bi bi-star-fill text-warning"></i></span>
                                            <span><i className="bi bi-star-fill text-warning"></i></span>
                                            <span><i className="bi bi-star-fill text-warning"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-img">
                                    <img src="./assets/img/team/male-icon.png" className="img-fluid" alt=""/>
                                </div>
                                <div className="review-info">
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, dolor illum eum delectus accusamus repellendus quas! Quae, non minima! Accusantium soluta iste et velit culpa?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}