
export const BASE_URL = window.location.origin; 
export const BASE_PATH = window.location.origin === 'http://localhost:3000'
                                                    ? "http://localhost/team.innovilla.in/" 
                                                    :"https://team.innovilla.in/" ;
export const API_PATH = BASE_PATH+"api/";
export const IMAGE_PATH = API_PATH+"assets/img/";


// Performance target for employees

export const TARGET_TEAM_LEAD = 800;
export const TARGET_TELE_SALES = 200;
