import React from "react";
export default function AboutUs() {
    return(
        <>
        <section id="about-us" className="about-us">
            <h1 hidden>Innovilla Private Limited</h1>
            <h2 hidden>Website</h2>
            <h3 hidden>Software</h3>
            <h4 hidden>Integration</h4>
            <h5 hidden>Promotion</h5>
            <h6 hidden>Sales</h6>

            <div className="container" data-aos="fade-up">
                <div className="row content">
                    <div className="col-lg-6 text-center" data-aos="fade-right">
                        <img src="./assets/img/innovilla_logo.jpg" alt="Innovilla Private Limited" width="100%"/>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                    <p className="" style={{textAlign: 'justify'}}>
                    Innovilla Private Limited Is founded by Mr. Mrityunjay Pandey and co founded by Mrs. S. Chaurasia 
                    to help out small scale businesses finding new business opoortunities using technology as core. Our Aim is to collaborate, analyze and guide   
                    with small and medium businesses and help them cope with modern business approach.
                    </p>
                    <p className="" style={{textAlign: 'justify'}}>
                    As the company slogan defines, our mission is to make business innovative using 
                    technology with proper retaintion of traditional customer base. Our Client first approach builds a good client-company relationship and it helps us Coverting ideas to implementation.
                    </p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}