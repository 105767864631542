import React from "react";
export default function AboutTeam(){
    const idcard = (name,profile,image)=>{
        return(
            <>
                <div className="col-lg-3 col-md-6 d-flex col-6 p-1 align-items-stretch">
                    <div className="member" data-aos="fade-up" data-aos-delay="100">
                        <div className="member-img"> <img src={`assets/img/team/${image}`} className="img-fluid" alt=""/>
                            
                        </div>
                        <div className="member-info">
                            <h4>{name}</h4> <span>{profile}</span> </div>
                    </div>
                </div>
            </>
        );
    }
    return(
        <>
        <section id="team" className="team section-bg">
            <div className="container">
                <div className="section-title" data-aos="fade-up">
                    <h2><strong>Team</strong> Innovilla</h2>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 d-flex col-0 p-1 align-items-stretch">
                        
                    </div>
                    {idcard('M Pandey','Founder & Managing Director','male-icon.png')}
                    {idcard('S Chaurasia','Director & Creative Head','female-icon.png')}
                    <div className="col-lg-3 col-md-6 d-flex col-6 p-1 align-items-stretch">
                        
                    </div>
                </div>
                <div className="row">
                    {idcard('Ankur Srivastava','Team Innovilla','ankur-ji.jpg')}
                    {idcard('Pradeep Gupta','Team Innovilla','pradeep.jpg')}
                    {idcard('Bhavna M','Team Innovilla','bhavna.jpg')}
                    {idcard('Azaharuddin Maniyar','Team Innovilla','male-icon.png')}
                    {idcard('Shaishtha Shireen','Team Innovilla','female-icon.png')}
                    {idcard('Anubhav Tiwari','Team Innovilla','male-icon.png')}
                    {idcard('Nitendra Sharma','Team Innovilla','male-icon.png')}
                    {idcard('Devendra Singh Rajput','Team Innovilla','male-icon.png')}
                    {idcard('Dadege Rohit','Team Innovilla','male-icon.png')}
                    {idcard('Anjali Soni','Team Innovilla','female-icon.png')}
                    {idcard('Shivami Bhambhani','Team Innovilla','female-icon.png')}
                    {idcard('Puneet Asthana','Team Innovilla','male-icon.png')}
                    {idcard('Kuldeep Katara','Team Innovilla','male-icon.png')}
                    {idcard('Shashwati Priyadarshini','Team Innovilla','female-icon.png')}
                    {idcard('Oliver','Happiness Officer','oliver.jpg')}
                </div>
            </div>
        </section>
        </>
    );
}